var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog addCorePersonnelDialog",
      attrs: {
        title: "新增人员1",
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
        open: _vm.setOrganizationPersonnelInit,
      },
    },
    [
      _vm.showDialog
        ? _c("selectOPRowDialog", {
            ref: "selectOPRowDialog",
            attrs: {
              roleId: _vm.roleId,
              isIndex: false,
              dataForm: _vm.dataForm,
            },
            on: { selectOPList: _vm.selectOPList },
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeDialog } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.dialogLoading,
              },
              on: { click: _vm.handleSave },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }