<template>
  <div class="projectSet-container" v-loading="!showPage">
    <head-layout
      class="box-border-no"
      :head-title="dataForm.prjName"
      :head-btn-options="headBtnOptions"
      @head-cancel="headCancel"
    ></head-layout>
      <div class="formContentBox shuttleBackBox" v-if="showPage" >
        <el-tabs
          tab-position="left"
          type="border-card"
          v-model="activeName"
          @tab-click="handleClick"
        >
<!--          <el-tab-pane v-if="!showTab ||showTab == '1'" name="basicForm">-->
<!--            <span slot="label"><i class="iconfont icon-xiangmugaikuang"></i> 项目概况</span>-->

<!--          </el-tab-pane>-->
<!--          <el-tab-pane v-if="!showTab ||showTab == '1'" name="organizationPersonnel">-->
<!--            <span slot="label"><i class="iconfont icon-bumenrenyuanguanli"></i> 组织&人员</span>-->

<!--            <organizationPersonnel-->
<!--              v-if="activeName == 'organizationPersonnel'"-->
<!--              ref="organizationPersonnel"-->
<!--              :dataForm="dataForm"-->
<!--            >-->
<!--            </organizationPersonnel>-->
<!--          </el-tab-pane>-->
          <!-- 原核心人员 -->
<!--          !showTab ||showTab == '1'-->
          <el-tab-pane v-if="false" name="corePersonnel">
            <span slot="label"><i class="iconfont icon-QCxunjian"></i> 项目角色授权</span>
            <el-container v-if="activeName == 'corePersonnel'">
              <CommonTree
                ref="commonTreeCore"
                treeTitle="项目角色"
                :searchTitle="searchTitle"
                :treeData="corePersonnelTreeData"
                :defaultProps="defaultProps"
                @getNodeClick="corePersonnelTreeNodeClick"
                :isShowdig="false"
                :showCheckbox="false"
                :treeExpand="false"
                node-key="id"
              />
              <div class="table-box">
                <head-layout

                  :showIcon="false"
                  head-title="项目角色授权"
                  :head-btn-options="corePersonnelHeadBtnOptions"
                  @head-add="handleSelect"
                  @head-no-use="handleIsUse(1, 'batch')"
                  @head-use="handleIsUse(0, 'batch')"
                ></head-layout>
                <grid-head-layout
                  ref="corePersonnelGridHeadLayout"
                  :search-columns="corePersonnelSearchColumns"
                  @grid-head-search="corePersonnelGridHeadSearch"
                  @grid-head-empty="corePersonnelGridHeadEmpty"
                ></grid-head-layout>
                <grid-layout
                  ref="gridLayOut"
                  :table-options="corePersonnelTableOption"
                  :table-data="corePersonnelTableData"
                  @gird-handle-select-click="selectionChange"
                  class="itemListGridNoPage"
                >
                  <template slot="birthday" slot-scope="{ row }">
                    <span :style="itemObject(row)">
                      {{ getAge(row.birthday) }}
                    </span>
                  </template>
                  <template #customBtn="{ row }" v-if="formType != 'view'">
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="
                        handleIsUse(row.isUsed === 1 ? 0 : 1, 'only', row)
                      "
                    >{{ row.isUsed === 1 ? "启用" : "停用" }}
                    </el-button>
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="rowdel(row)"
                    >删除
                    </el-button>
                  </template>
                </grid-layout>
              </div>
            </el-container>
          </el-tab-pane>
          <!-- <el-tab-pane v-if="!showTab ||showTab == '2'" name="thirdPartyOrganization">
            <span slot="label"><i class="iconfont icon-zuzhibumen"></i> 相关方组织</span>
            <el-container v-if="activeName == 'thirdPartyOrganization'">
              <div class="table-box" style="width: 100%">
                <head-layout
                  :showIcon="false"
                  head-title="相关方组织"
                  :head-btn-options="thirdPartyHeadBtnOptions"
                  @head-add="headAddThirdPartyOrganization"
                  @head-no-use="handleIsUse(1, 'batch')"
                  @head-use="handleIsUse(0, 'batch')"
                ></head-layout>
                <grid-head-layout
                  ref="thirdPartyOrganizationGridHeadLayout"
                  :search-columns="thirdPartyOrganizationSearchColumns"
                  @grid-head-search="thirdPartyOrganizationGridHeadSearch"
                  @grid-head-empty="thirdPartyOrganizationGridHeadEmpty"
                ></grid-head-layout>
                <grid-layout
                  ref="thirdPartyOrganizationGridLayOut"
                  :table-options="thirdPartyOrganizationTableOption"
                  :table-data="organizationTableData"
                  @grid-row-detail-click="rowView"
                  :table-loading="tableLoading"
                  @gird-handle-select-click="selectionChange"
                  class="itemListGridNoPage"
                >
                  <template slot="baseCode" slot-scope="{ row }">
                    <el-image :preview-src-list="[formatter(row)]" style="padding-top:10px;width: 40px;" :src="formatter(row)"></el-image>
                  </template>
                  <template slot="isUsed" slot-scope="{ row }">
                    <el-tag :type="row.isUsed == '0' ? 'success' :'danger'">{{row.$isUsed}}</el-tag>
                  </template>
                  <template #customBtn="{ row }" v-if="formType != 'view'">
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="
                        handleIsUse(row.isUsed === 1 ? 0 : 1, 'only', row)
                      "
                    >{{ row.isUsed === 1 ? "入场" : "离场" }}
                    </el-button>
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="rowView(row, 'edit')">编辑</el-button>
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="rowdel(row)"
                    >删除
                    </el-button>
                    <el-button
                      style="margin: 0 3px"
                      type="text"
                      size="small"
                      @click="openBlackList(row, 'organization')"
                    >黑名单
                    </el-button>
                  </template>
                </grid-layout>
              </div>
            </el-container>
          </el-tab-pane>
          <el-tab-pane v-if="!showTab ||showTab == '2'" name="thirdPartyPersonnel">
            <span slot="label"><i class="iconfont icon-renyuan"></i> 相关方人员</span>
            <el-container v-if="activeName == 'thirdPartyPersonnel'">
              <CommonTree
                ref="commonTreeUser"
                treeTitle="项目架构"
                :treeData="userThirdPartyOrganizationTableData"
                :defaultProps="thirdPartyPersonnelDefaultProps"
                @getNodeClick="thirdPartyPersonnelTreeNodeClick"
                :isShowdig="false"
                :showCheckbox="false"
                :treeExpand="false"
                node-key="id"
              />
              <div class="table-box">
                <head-layout
                  :showIcon="false"
                  head-title="相关方人员"
                  @head-px="addPx(selectionList)"
                  @head-no-use="handleIsUse(1, 'batch')"
                  @head-use="handleIsUse(0, 'batch')"
                  :head-btn-options="corePersonnelHeadBtnOptions"
                  @head-add="headAddThirdPartyPersonnel"
                  @head-quote="handleThirdPersonnelQuote"
                  @head-import="handleImportThirdPersonnel"
                ></head-layout>
                <grid-head-layout
                  ref="thirdPartyPersonnelGridHeadLayout"
                  :search-columns="thirdPersonnelSearchColumns"
                  @grid-head-search="thirdPartyPersonnelGridHeadSearch"
                  @grid-head-empty="thirdPartyPersonnelGridHeadEmpty"
                ></grid-head-layout>
                <grid-layout

                  ref="thirdPartyPersonnelTableData"
                  :page="page"
                  @page-current-change="getPrjthirduserPage" @page-size-change="getPrjthirduserPage"
                  :data-total="page.total"
                  @grid-row-detail-click="rowUserView"
                  :table-options="thirdPartyPersonnelTableOption"
                  :table-data="thirdPartyPersonnelTableData"
                  :table-loading="tableLoading"
                  @gird-handle-select-click="selectionChange"
                  class="itemListGridNoPage"
                >
                  <template slot="isUsed" slot-scope="{row}">
                    <el-tag effect="dark" :type="isUsedDict[row.isUsed]">{{row.$isUsed}}</el-tag>
                  </template>
                  <template slot="baseCode" slot-scope="{ row }">
                   <el-image :preview-src-list="[formatter(row)]" style="padding-top:10px;width: 40px;" :src="formatter(row)"></el-image>
                  </template>
                  <template slot="birthday" slot-scope="{ row }">
                      <span :style="itemObject(row)">
                      {{ getAge(row.birthday) }}
                    </span>
                  </template>

                  <template #customBtn="{ row }" v-if="formType != 'view'">
                      <head-layout class="margin-b-10 list-box" head-title="" :row="row"
                                   :head-btn-options="userBtnOptions"></head-layout>
                  </template>
                </grid-layout>
              </div>
            </el-container>
          </el-tab-pane> -->
          <!-- <el-tab-pane v-if="!showTab ||showTab == '2'" name="blacklistOrganization">
            <span slot="label"><i class="iconfont icon-heimingdanguanli"></i> 黑名单组织</span>
            <head-layout
              head-title="黑名单组织"
              :showIcon="false"
            />
            <grid-head-layout v-if="activeName == 'blacklistOrganization'"
                              ref="blacklistOrganizationGridHeadLayout"
                              :search-columns="blacklistOrganizationSearchColumns"
                              @grid-head-search="blacklistOrganizationGridHeadSearch"
                              @grid-head-empty="blacklistOrganizationGridHeadEmpty"
            ></grid-head-layout>
            <grid-layout
              v-if="activeName == 'blacklistOrganization'"
              ref="blacklistOrganizationGridLayOut"
              :table-loading="tableLoading"
              :table-options="blacklistOrganizationTableOption"
              :table-data="blacklistOrganizationTableData"
              class="itemListGridNoPage"
            >
              <template #customBtn="{ row }" v-if="formType != 'view'">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="removeBlack(row,'organization')"
                >移除黑名单
                </el-button>
              </template>
            </grid-layout>
          </el-tab-pane> -->
          <el-tab-pane v-if="!showTab ||showTab == '2'" name="blacklistPersonnel">
            <span slot="label"><i class="iconfont icon-heimingdan"></i> 黑名单人员</span>
            <head-layout
              head-title="黑名单人员"
              :showIcon="false"
            />
            <grid-head-layout v-if="activeName == 'blacklistPersonnel'"
                              ref="blacklistPersonnelGridHeadLayout"
                              :search-columns="blacklistPersonnelSearchColumns"
                              @grid-head-search="blacklistPersonnelGridHeadSearch"
                              @grid-head-empty="blacklistPersonnelGridHeadEmpty"
            ></grid-head-layout>
            <grid-layout v-if="activeName == 'blacklistPersonnel'"
                         ref="blacklistPersonnelGridLayOut"
                         :table-loading="tableLoading"
                         :table-options="blacklistPersonnelTableOption"
                         :table-data="blacklistPersonnelTableData"
                         class="itemListGridNoPage"
            >
              <template slot="birthday" slot-scope="{ row }">
                <span :style="itemObject(row)">
                  {{
                    getAge(row.birthday)
                  }}
                </span>
              </template>
              <template #customBtn="{ row }" v-if="formType != 'view'">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  size="small"
                  @click="removeBlack(row,'personnel')"
                >移除黑名单
                </el-button>
              </template>
            </grid-layout>
          </el-tab-pane>
          <el-tab-pane v-if="!showTab ||showTab == '1'" name="projectArea">
            <span slot="label"><i class="iconfont icon-zhishiditu"></i> 项目区域</span>
            <projectArea v-if="activeName == 'projectArea'" ref="projectArea"></projectArea>
          </el-tab-pane>
          <!-- <el-tab-pane v-if="!showTab ||showTab == '1'" name="personEAExit">
            <span slot="label"><i class="iconfont icon-churujing1"></i> 人员出入境</span>
            <personEAExit v-if="activeName == 'personEAExit'"
                          ref="personEAExit"
                          :prjName="dataForm.prjName"
            ></personEAExit>
          </el-tab-pane> -->
          <!-- <el-tab-pane v-if="!showTab ||showTab == '2'" name="relatedEAExit">
            <span slot="label"><i class="iconfont icon-churujing"></i> 相关方出入境</span>
            <relatedEAExit v-if="activeName == 'relatedEAExit'"
                           ref="relatedEAExit"
                           :prjName="dataForm.prjName"
            ></relatedEAExit>
          </el-tab-pane> -->
          <!-- 项目进出场日志 -->
          <el-tab-pane v-if="!showTab ||showTab == '1'" name="projectEAExitLog">
            <span slot="label"><i class="iconfont icon-rizhi"></i> 项目进出场日志</span>
            <projectEAExitLog v-if="activeName == 'projectEAExitLog'"
                              ref="projectEAExitLog"
                              :prjName="dataForm.prjName"
            ></projectEAExitLog>
          </el-tab-pane>
          <el-tab-pane v-if="dataForm.prjType == 1" name="greenFactory">
            <span slot="label"><i class="iconfont icon-gongchang"></i> 绿色工厂</span>
            <greenFactory v-if="activeName == 'greenFactory'"
                          ref="greenFactory"
                          :prjName="dataForm.prjName"
            ></greenFactory>
          </el-tab-pane>
          <el-tab-pane v-if="dataForm.prjType == 2" name="greenMines">
            <span slot="label"><i class="iconfont icon-kuangshan"></i> 绿色矿山</span>
            <greenMines v-if="activeName == 'greenMines'"
                        ref="greenMines"
                        :prjName="dataForm.prjName"
            ></greenMines>
          </el-tab-pane>
          <el-tab-pane name="personnel_positioning">
            <span slot="label"><i class="iconfont el-icon-film"></i> 人员定位</span>
          </el-tab-pane>
          <el-tab-pane name="gate">
            <span slot="label"><i class="iconfont el-icon-film"></i> 闸机管理</span>
            <gate
              v-if="activeName == 'gate'"
              ref="gate"
              :prjName="dataForm.prjName"
              :prjId="dataForm.id"
            ></gate>
          </el-tab-pane>
          <el-tab-pane name="visitorReservation">
            <span slot="label"><i class="iconfont el-icon-s-custom"></i> 访客预约</span>
            <visitorReservation
              v-if="activeName == 'visitorReservation'"
              ref="visitorReservation"
              :prjName="dataForm.prjName"
              :prjId="dataForm.id"
            ></visitorReservation>
          </el-tab-pane>
          <el-tab-pane name="visitorRecord">
            <span slot="label"><i class="iconfont el-icon-s-custom"></i> 访客记录</span>
            <visitorRecord
              v-if="activeName == 'visitorRecord'"
              ref="visitorRecord"
              :prjName="dataForm.prjName"
              :prjId="dataForm.id"
            ></visitorRecord>
          </el-tab-pane>
         

        </el-tabs>
      </div>
    <!-- 人员选择弹窗 -->
    <user-select
      ref="userSelect"
      @callBack="handleUserSelectConfirm"
    ></user-select>
    <thirdPartyOrgQuote
      ref="thirdPartyOrgQuote"
      @callback="getPrjthirdcompanyPage"
    ></thirdPartyOrgQuote>
    <!-- 相关方人员新增 -->
    <addThirduser
      ref="addThirduser"
      @callback="getPrjthirduserPage"
    ></addThirduser>
    <addThirduserQuote
      ref="addThirduserQuote"
      @callback="getPrjthirduserPage"
    ></addThirduserQuote>


    <!-- 项目角色授权新增 -->
    <addCorePersonnelDialog
      ref="addCorePersonnelDialog"
      :dataForm="dataForm"
      @changeOPData="handleUserSelectConfirm"
    >
    </addCorePersonnelDialog>
    <!-- 导入 -->
    <el-dialog
      title="相关方人员导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          :upload-after="uploadAfter"
          v-if="excelBox"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{
                $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  dictionaryBiz,
} from "@/api/reportTasks";
import {dateFormat} from "@/util/date";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import userSelect from "../components/user-select";
import thirdPartyOrgQuote from "./thirdPartyOrgQuote";
import addThirduserQuote from "./addThirduserQuote";
import BaiduMap from "@/views/system/attendance/BaiduMap";
import OpenBlackDialog from "./openBlackDialog.vue";
// import projectArea from "./projectArea.vue"; // 原项目区域
import projectArea from "./projectArea/index.vue"; // 2024/8/21 新项目区域
import personEAExit from "./personEAExit.vue";
import relatedEAExit from "./relatedEAExit.vue";
import projectEAExitLog from "./projectEAExitLog.vue";
import greenFactory from "./greenFactory.vue";
import greenMines from "./greenMines.vue";
import addCorePersonnelDialog from "@/views/business/safe/itemList/components/addCorePersonnelDialog.vue";
import gate from "./gate/list";
import visitorReservation from "./visitorReservation/list"
import visitorRecord from "./visitorRecord/list"
import ElTreeSelect from '@/components/ElTreeSelect';

import {
  prjbasicinfoDetail,
  prjbasicinfoSubmit,
  prjroleuserList,
  prjroleuserSubmit,
  prjthirdcompanyPage,
  prjthirduserPage,
  prjthirduserBatchSubmit,
  prjroleuserRemove,
  prjthirdcompanyRemove,
  prjthirduserRemove,
  prjthirdcompanyBatchSubmit,
} from "@/api/safe/itemList";
import {
  getDeptTree,
  getDeptLazyTree,
} from "@/api/system/dept";
import {classificationRoleGetList} from "@/api/system/role";
import {getAreaData1} from "@/api/base/region";
import {prjblacklistorgheadPage} from "@/api/block/organization";
import {prjblacklistuserheadPage} from "@/api/block/personnel";
import {prjQrcode} from "@/api/safe/itemList";
import {downloadXls} from "@/util/util";
import {exportBlob} from "@/api/common";
import organization from "@/views/business/safe/itemList/organization";
import {mapGetters} from "vuex";

export default {
  name:'projectEdit',
  components: {ElTreeSelect,
    CommonTree,
    GridLayout,
    HeadLayout,
    userSelect,
    thirdPartyOrgQuote,
    addThirduserQuote,
    BaiduMap,
    OpenBlackDialog,
    projectArea,
    personEAExit,
    relatedEAExit,
    projectEAExitLog,
    greenFactory,
    greenMines,
    addCorePersonnelDialog,
    gate,
    visitorReservation,
    visitorRecord,
  },
  mixins: [organization],
  data() {
    return {
      showTab: '',
      formType: "",
      showPage: false,
      dataForm: {
        id: "",
        areName: "",
        contractId: "",
        contractName: "",
        isDomestic: "",
        latitude: "",
        longitude: "",
        coordinate: "",
        prjAs: "",
        prjDate: "",
        prjInfoState: "",
        prjJoinDate: "",
        prjName: "",
        prjCode: "",
        prjState: "0",
        phoneCount: "", // 卫星电话数量
        areaId: [],
        areaName: "",
        areaCode: "",
        areaRestock: "", //项目位置补充信息
        pmCame: "", // 项目经理
        phoneNumber: "", // 联系电话
        responsibleUnitName: "", //项目区域责任单位名称
        prjType: "",
      },
      prjTypeList: [], // 项目类型
      stageList: [], // 建设/运维
      oldDataForm: {},

      corePersonnelSearchColumns: [
        {
          label: "姓名",
          prop: "userName",
          span: 4,
          placeholder: "请输入人员姓名",
        },
      ],
      // 相关方人员搜索
      thirdPersonnelSearchColumns: [
        {
          label: "姓名",
          prop: "userName",
          placeholder: "请输入人员姓名",
        },
        {
          label: "岗位",
          prop: "post",
          span: 4,
          placeholder: "请输入岗位",
        },
        {
          label: "年龄",
          startProp: "startAge",
          endProp: "endAge",
          span: 4,
          startPlaceholder: "开始年龄",
          endPlaceholder: "结束年龄",
          type: 'numberRange',
        },
        {
          label: "是否特种人员",
          prop: "isSpecial",
          span: 4,
          placeholder: "请选择是否特种人员",
          type: "select",
          dicData: [
            {value: 1, label: '是'},
            {value: 0, label: '否'},
          ]
          // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=yesNo`,
          // props: {
          //   label: 'dictValue',
          //   value: 'dictKey',
          // },
        },
      ],
      corePersonnelTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 130,
        selection: this.$route.query.type != "view",
        menu: this.$route.query.type != "view",
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "性别",
            prop: "sex",
            minWidth: 80,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            minWidth: 80,
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "联系电话",
            prop: "phone",
            minWidth: 180,
            align: "center",
            overHidden: true,
          },
          // {
          //   label: "岗位",
          //   prop: "post",
          //   width: 150,
          //   align: "center",
          //   overHidden: true
          // },
          {
            label: "启用",
            prop: "isUsed",
            dataType: "number",
            minWidth: 80,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            align: "center",
          },
          {
            label: "更新时间",
            prop: "updateTime",
            minWidth: 170,
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      corePersonnelTableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "roleName",
      },
      activeName: "basicForm",
      searchTitle: "roleName",
      corePersonnelTreeData: [],
      corePersonnelTreeNode: "",
      thirdPartyOrganizationSearchColumns: [
        {
          label: "统一社会信用代码",
          prop: "companyCode",
          span: 4,
          placeholder: "请输入统一社会信用代码",
        },
        {
          label: "公司名称",
          prop: "companyName",
          span: 4,
          placeholder: "请输入公司名称",
        },
      ],
      organizationTableData:[],
      thirdPartyOrganizationTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 170,
        linklabel: "companyCode",
        selection: this.$route.query.type != "view",
        menu: this.$route.query.type != "view",
        column: [
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            align: "center",
            minWidth: 185,
            overHidden: true,
          },
          {
            slot: true,
            label: '二维码', align: "center", prop: 'baseCode',
          },
          {
            label: "公司名称",
            prop: "companyName",
            minWidth: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            minWidth: 120,
            align: "center",
            overHidden: true,
          },
          {
            label: "详细位置",
            prop: "regLocation",
            minWidth: 150,
            align: "left",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "isUsed",
            dataType: "number",
            slot:true,
            minWidth: 80,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicData:[
              {dictKey:'0',dictValue:'已入场'},
              {dictKey:'1',dictValue:'已离场'},
            ],
            align: "center",
          },
          {
            label: "黑名单状态",
            prop: "inBlacklist",
            minWidth: 95,
            align: "center",
            formatter: (row, value, label) => {
              return row.inBlacklist ? "是" : "否";
            },
            overHidden: true,
          },
          {
            label: "更新时间",
            prop: "updateTime",
            minWidth: 170,
            align: "center",
            overHidden: true,
          },
        ],
      },
      thirdPartyOrganizationTableData: [],
      userThirdPartyOrganizationTableData: [],


      // 黑名单人员 筛选条件
      blacklistPersonnelSearchColumns: [
        {
          label: "",
          prop: "condition",
          span: 4,
          placeholder: "姓名/籍贯/身份证号码",
        },
      ],


      thirdPartyPersonnelDefaultProps: {
        children: "children",
        label: "title",
      },
      thirdPartyPersonnelTreeNode: "",
      thirdPartyPersonnelTableOption: {
        index: true,
        indexFixed: true,
        linklabel: 'userName',
        indexLabel: "序号",
        menuWidth: 200,
        selectable:(row)=>{
          return row.isUsed == 0 || row.isUsed == 2 || !row.trained
        },
        selection: this.$route.query.type != "view",
        menu: this.$route.query.type != "view",
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            minWidth: 120,
            fixed: true,
            overHidden: true,
          },
          {
            slot: true,
            label: '二维码', align: "center", prop: 'baseCode',
          },
          {
            label: "性别",
            prop: "sex",
            minWidth: 80,
            overHidden: true,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            minWidth: 80,
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "联系电话",
            prop: "phone",
            align: "center",
            minWidth: 180,
            overHidden: true,
          },
          {
            label: "身份证",
            prop: "idCoding",
            minWidth: 185,
            align: "center",
            overHidden: true,
          },
          {
            label: "籍贯",
            prop: "domicile",
            minWidth: 150,
            align: "left",
            overHidden: true,
          },
          {
            label: "工种",
            prop: "stPostName",
            minWidth: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: "黑名单",
            prop: "inBlacklist",
            align: "center",
            minWidth: 70,
            formatter: (row, value, label) => {
              return row.inBlacklist ? "是" : "否";
            },
            overHidden: true,
          },
          {
            label: "通过培训",
            prop: "trained",
            minWidth: 100,
            align: "center",
            overHidden: true,
            dicData: [
              {value: true, label: '是'},
              {value:false, label: '否'},
            ]
          },
          {
            label: "特种人员",
            prop: "isSpecial",
            minWidth: 100,
            align: "center",
            overHidden: true,
            dicData: [
              {value: 1, label: '是'},
              {value: 0, label: '否'},
            ]
          },
          {
            label: "状态",
            prop: "isUsed",
            dataType: "number",
            minWidth: 80,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            slot: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_user_status",
            align: "center",
          },
          {
            label: "更新时间",
            prop: "updateTime",
            minWidth: 170,
            align: "center",
            overHidden: true,
          },
        ],
      },
      thirdPartyPersonnelTableData: [],
      selectionList: [],
      organDic: [],
      qrcodeSrc: "", // 二维码图片src
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            data: {prjId: "", companyId: ""},
            action: "/api/sinoma-hse-prj/prjthirduser/import",
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      isMenu: false,
    };
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    //   头部按钮设置
    headBtnOptions() {
      let result = [];
      if (!this.isMenu) {
        result.push({
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        });
      }
      return result;
    },


    //   核心人员
    corePersonnelHeadBtnOptions() {
      let btnList = []
      if(this.formType!='view'){
        btnList.push({
          label: "新增人员",
          emit: "head-add",
          type: "button",
          icon: "",
          // btnOptType: 'add',
        })

      if (this.activeName == "thirdPartyPersonnel") {
        btnList.push({
          label: "引入",
          emit: "head-quote",
          type: "button",
        },{
            label: "批量培训",
            emit: "head-px",
            type: "button",
            icon: "",
            btnOptType: "no-use",
          },{
            label: "批量入场",
            emit: "head-use",
            type: "button",
            icon: "",
            btnOptType: "no-use",
          },
          {
            label: "批量离场",
            emit: "head-no-use",
            type: "button",
            icon: "",
            btnOptType: "use",
          },
          {
          label: "导入",
          emit: "head-import",
          type: "button",
        });
      } else {
        btnList.push({
            label: "批量停用",
            emit: "head-no-use",
            type: "button",
            icon: "",
            btnOptType: "no-use",
          },
          {
            label: "批量启用",
            emit: "head-use",
            type: "button",
            icon: "",
            btnOptType: "use",
          },)
      }
      }

      return btnList;
    },
    // 相关方组织
    thirdPartyHeadBtnOptions(){
      let btnList = []
      if(this.formType!='view'){
        btnList = [
        {
          label: "新增公司",
          emit: "head-add",
          type: "button",
          icon: "",
          // btnOptType: 'add',
        },
        // {
        //   label: "引入",
        //   emit: "head-quote-thirdOrg",
        //   type: "button",
        //   icon: "",
        //   // btnOptType: 'add',
        // },
        {
          label: "批量离场",
          emit: "head-no-use",
          type: "button",
          icon: "",
          btnOptType: "no-use",
        },
        {
          label: "批量入场",
          emit: "head-use",
          type: "button",
          icon: "",
          btnOptType: "use",
        },
        ]
      }
      return btnList
    }
  },
  mounted() {
    this.formType = this.$route.query.type;
    this.showTab = this.$route.query.showTab;
    this.activeName = !this.showTab ? 'basicForm' : this.showTab == '1' ? 'basicForm' : 'thirdPartyOrganization'
    if (this.$route.query.isMenu) {
      this.isMenu = true;
    }
    if (this.formType == "edit" || this.formType == "view") {
      this.handleClick()
    }

  },
  activated() {

  },
  methods: {

    handleClick() {
      this.$nextTick(() => {
        this.selectionList = [];
        if (this.activeName == "corePersonnel") {
          this.getClassificationRoleList();
          this.getPrjroleuserList();
        } else if (this.activeName == "organizationPersonnel") {
          this.$refs.organizationPersonnel.init();
        } else if (this.activeName == "thirdPartyOrganization") {
          this.thirdPartyOrganization();
        } else if (this.activeName == "thirdPartyPersonnel") {
          this.getPrjthirdcompanyPage();
          this.getPrjthirduserPage();
        } else if (this.activeName == "blacklistOrganization") {
          // 黑名单组织
          this.getBlacklistOrganizationPage();
        } else if (this.activeName == "blacklistPersonnel") {
          // 黑名单人员
          this.getBlacklistPersonnelPage();
        } else if (this.activeName == "projectArea") {
          // 项目区域
          this.$refs.projectArea.init(this.dataForm.id, 'ced',this.dataForm.deptSegment);
        } else if (this.activeName == "personEAExit") {
          // 人员出入境
          this.$refs.personEAExit.init(this.dataForm.id);
        } else if (this.activeName == "relatedEAExit") {
          // 相关方出入境
          this.$refs.relatedEAExit.init(this.dataForm.id);
        } else if (this.activeName == "projectEAExitLog") {
          // 项目进出场日志
          this.$refs.projectEAExitLog.init(this.dataForm.id);
        } else if (this.activeName == "greenFactory") {
          // 绿色工厂
          this.$refs.greenFactory.init(
            this.dataForm.id,
            this.dataForm.contractId
          );
        } else if (this.activeName == "greenMines") {
          // 绿色矿山
          this.$refs.greenMines.init(this.dataForm.id, this.dataForm.contractId);
        } else if(this.activeName=='personnel_positioning'){
          let toUrl = process.env.VUE_APP_BASE_PERSONNEL_POSITIONING + this.dataForm.prjCode;
          window.open(toUrl)
        }
      })
    },



    //获取项目角色
    getClassificationRoleList() {
      classificationRoleGetList(1, 9999, {typeCode: "PROJECT"}).then(
        (res) => {
          this.corePersonnelTreeData = res.data.data.records;
          if (this.corePersonnelTreeData.length) {
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.corePersonnelTreeData[0].id
              );
              this.corePersonnelTreeNodeClick(this.corePersonnelTreeData[0]);
            });
          }
        }
      );
    },

    // 项目角色人员列表筛选条件搜索
    // 搜索
    corePersonnelGridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.getPrjroleuserList(searchForm);
    },
    // 清空
    corePersonnelGridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.getPrjroleuserList();
    },
    //根据角色获取人员列表
    getPrjroleuserList(params = {}) {

      let obj = {
        prjId: this.dataForm.id,
        roleId: this.corePersonnelTreeNode.id,
      };
      prjroleuserList(Object.assign(params, obj)).then((res) => {
        this.corePersonnelTableData = res.data.data;
      });
    },
    // 项目角色人员查询
    corePersonnelTreeNodeClick(node) {
      this.corePersonnelTreeNode = node;
      this.getPrjroleuserList();
    },
    //选择人员
    handleSelect() {
      if (this.activeName === "corePersonnel") {
        if (this.corePersonnelTreeNode != "") {
          // 需要根据左侧角色树的id区过滤已绑定的人
          this.$refs.addCorePersonnelDialog.init(
            this.corePersonnelTableData,
            this.corePersonnelTreeNode
          );
          // this.$refs.userSelect.init(this.corePersonnelTableData)
        } else {
          return this.$message.warning('请选择项目角色');
        }
      }
    },
    //批量添加人员
    handleUserSelectConfirm(data) {
      if (data && data.length != 0) {
        if (this.activeName === "corePersonnel") {
          let queryData = data.map((item) => {
            return {
              sex: item.sex,
              prjId: this.dataForm.id,
              roleId: this.corePersonnelTreeNode.id,
              birthday:
                item.birthday && item.birthday != ""
                  ? dateFormat(new Date(item.birthday), "yyyy-MM-dd")
                  : "",
              userId: item.userId,
              userName: item.userName,
              post: item.post,
              isUsed: item.isUsed,
            };
          });
          this.prjroleuserSubmitSave(queryData);
        }
      }
    },
    //核心人员批量添加
    prjroleuserSubmitSave(data) {
      prjroleuserSubmit(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('操作成功');
          this.getPrjroleuserList();
        }
      });
    },
    //核心人员删除
    prjroleuserRemoveData(data) {
      prjroleuserRemove({
        ids: data.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('操作成功');
        }
        this.getPrjroleuserList();
      });
    },
    //相关方组织
    prjthirdcompanyBatchSubmitSave(data) {
      prjthirdcompanyBatchSubmit(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('操作成功');
          this.thirdPartyOrganization();
        }
      });
    },
    //相关方组织删除
    prjthirdcompanyRemoveData(data) {
      prjthirdcompanyRemove({
        ids: data.id,
        prjId:this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('操作成功');
          this.thirdPartyOrganization();
        }
      });
    },
    //相关方人员
    prjthirduserBatchSubmitSave(data) {
      prjthirduserBatchSubmit(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('操作成功');
          this.getPrjthirduserPage();
        }
      });
    },
    //相关方人员删除
    prjthirduserRemoveData(data) {
      prjthirduserRemove({
        ids: data.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('操作成功');
        }
        this.getPrjthirduserPage();
      });
    },

    //批量选择
    selectionChange(list) {
      this.selectionList = list;
    },
    //批量启用/停用
    handleIsUse(status, type, data) {
      let queryData = [];
      if (type != "only") {
        if (!this.selectionList.length) {
          this.$message({
            type: "warning",
            message: "请先选择需要操作的数据！",
          });
          return;
        } else {
          queryData = this.selectionList.map((item) => {
            return {
              ...item,
              isUsed: status,
              prjId:this.dataForm.id,
              createBaseCode:'no',
            };
          });
        }
      } else {
        queryData.push({
          ...data,
          isUsed: status,
          prjId:this.dataForm.id,
          createBaseCode:'no',
        });
      }
      if(this.activeName == 'thirdPartyOrganization' && status == 0){
        this.prjthirdcompanyBatchSubmitSave(queryData);
        return;
      }
      let message={
        'thirdPartyOrganization':'<span style="color: red">相关方组织离场，组织下所有人员均会被设置为离场状态，确认离场？</span>',
        'corePersonnel':'确定批量处理当前所选信息',
        'thirdPartyPersonnel':'确定批量处理当前所选信息',
      }
      this.$confirm(message[this.activeName], "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          if (this.activeName === "corePersonnel") {
            this.prjroleuserSubmitSave(queryData);
          } else if (this.activeName === "thirdPartyOrganization") {
            this.prjthirdcompanyBatchSubmitSave(queryData);
          } else {
            this.prjthirduserBatchSubmitSave(queryData);
          }
        })
        .catch(() => {
        });
    },
    //列表删除
    rowdel(data) {
      this.$confirm("是否确认删除此数据?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          if (this.activeName === "corePersonnel") {
            this.prjroleuserRemoveData(data);
          } else if (this.activeName === "thirdPartyOrganization") {
            this.prjthirdcompanyRemoveData(data);
          } else {
            this.prjthirduserRemoveData(data);
          }
        })
        .catch(() => {
        });
    },



    // 相关方组织搜索条件筛选
    // 搜索
    thirdPartyOrganizationGridHeadSearch(searchForm) {
      this.thirdPartyOrganization(searchForm);
    },
    // 清空
    thirdPartyOrganizationGridHeadEmpty(searchForm) {
      this.thirdPartyOrganization();
    },
    thirdPartyOrganization(params={}){
      let obj = {
        prjId: this.dataForm.id,
      };
      prjthirdcompanyPage(Object.assign(params, obj)).then((res) => {
        if ((res.data.code = 200)) {
          this.organizationTableData=res.data.data
        }
      });
    },
    // 相关方组织
    getPrjthirdcompanyPage() {
      const parentId = this.dataForm.id;
      getDeptTree(undefined,undefined,parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.userThirdPartyOrganizationTableData=[
            {
              ...res.data.data[0],
              children:res.data.data[0]['children'].filter(item=>item.thirdFlg == 1)
            }
          ]
          if(res.data.data.length >0){
            this.$refs.commonTreeUser?.setCurrentKey(this.userThirdPartyOrganizationTableData[0].id)
            this.thirdPartyPersonnelTreeNodeClick(this.userThirdPartyOrganizationTableData[0])
          }
        }
      });
    },
    //相关方组织新增
    headAddThirdPartyOrganization() {
      this.$router.push({path:'/safe/itemList/index/addThirdPartyOrganization',query:{type:'add',prjId:this.dataForm.id}})
    },
    // 相关方组织引入
    handleThirdOrgQuote() {
      this.$refs.thirdPartyOrgQuote.init(this.dataForm.id);
    },
    //查看
    rowView(row,type) {
      this.$router.push({path:'/safe/itemList/index/addThirdPartyOrganization',query:{type:type || 'view',prjId:this.dataForm.id,id:row.id}})
    },

    thirdPartyPersonnelTreeNodeClick(node) {
      this.thirdPartyPersonnelTreeNode = node;
      this.excelOption.column[0].data = {
        prjId: this.$route.query.id,
        companyId: node.id,
      };
      this.getPrjthirduserPage();
    },
    // 相关方人员条件筛选
    // 搜索
    thirdPartyPersonnelGridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.getPrjthirduserPage(searchForm);
    },
    // 清空
    thirdPartyPersonnelGridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.getPrjthirduserPage();
    },
    //项目相关方人员
    getPrjthirduserPage(page,params = {}) {
      if (this.thirdPartyPersonnelTreeNode != "") {
        this.page=page || this.page
        let obj = {
          thirdFlg:1,
          prjId: this.dataForm.id,
          deptId: this.thirdPartyPersonnelTreeNode.id,
          current:this.page.currentPage,
          size:this.page.pageSize,
        };
        this.tableLoading=true
        prjthirduserPage(Object.assign(params, obj)).then((res) => {
          this.thirdPartyPersonnelTableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.thirdPartyPersonnelTableData.page.total = res.data.data.total;
          this.tableLoading=false
        });
      } else {
        this.thirdPartyPersonnelTableData = [];
      }
    },
    //项目相关方人员新增
    headAddThirdPartyPersonnel() {
      if (!this.thirdPartyPersonnelTreeNode) return this.$message.warning('请选择相关方公司')
      this.$router.push({path:'/safe/itemList/index/addThirduser',query:{type:'add',deptId:this.thirdPartyPersonnelTreeNode.id,prjId:this.dataForm.id}})
    },
    // 项目相关方人员引用
    handleThirdPersonnelQuote() {
      if (!this.thirdPartyPersonnelTreeNode) return this.$message.warning('请选择相关方公司');
      this.$refs.addThirduserQuote.init(
        this.dataForm.id,
        this.thirdPartyPersonnelTreeNode.id
      );
    },

    recurTree(data) {
      data.forEach((item) => {
        if (item.title) {
          item.label = item.title;
        }
        if (item.children) {
          this.recurTree(item.children);
        }
        if (item.postName) {
          item.label = item.postName;
        }
        if (item.id) {
          item.value = item.id;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },






    handleImportThirdPersonnel() {
      if (
        this.thirdPartyPersonnelTreeNode &&
        this.thirdPartyPersonnelTreeNode.id
      ) {
        this.excelBox = true;
      } else {
        this.$message.warning("请先选择相关方公司");
      }
    },

    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.getPrjthirduserPage();
      done();
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-hse-prj/prjthirduser/exportTemplate`).then(
        (res) => {
          downloadXls(res.data, "相关方人员模板.xlsx");
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.projectSet-container {
  background-color: #ffffff;
  height: 100%;
  // padding: 0 12px;
  box-sizing: border-box;
   ::v-deep .el-tabs__content{
     height: 100%;
     .el-tab-pane{
       height: 100%;
       .el-container{
         height: 100%;
         .common_tree_handle{
           height: 100%;
           .el-tree{
             height: calc(100% - 100px);
           }
         }
       }

     }
   }
  .box-border-no {
      border-bottom: 0;
  }
}

.list-box {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;

  ::v-deep .headLabel {
    display: none;
  }
  ::v-deep .headBtn {
    width: 100%;
    justify-content: center;
     .badgeValue{
      padding: 0 3px;
    }
  }
}

::v-deep .addCorePersonnelDialog .el-tree {
  // height: calc(100vh - 435px) !important;
  height: 50vh !important;
  max-height: 50vh !important;
}

::v-deep .addCorePersonnelDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}

.formMain {
  width: 100%;
  // overflow-y: auto;
  height: calc(100vh - 200px) !important;

  form {
    padding: 10px !important;
    box-sizing: border-box !important;
  }

  // ::v-deep .el-header{
  // padding: 0 12px !important;
  // }
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  box-sizing: border-box;
  height: calc(100% - 55.8px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  .el-tabs {
    width: 100%;
  }

  .table-box {
    width: calc(100% - 280px);
  }


  ::v-deep .el-tabs__content {
    padding: 0;
  }

  ::v-deep .el-tabs__nav-scroll {
    background: #fff;
    margin-right: 10px;
    padding-top: 15px;
    width: 180px;
  }

  ::v-deep .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0;
  }

  ::v-deep .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
    margin: 0 10px;
    border: none !important;
  }

  ::v-deep .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
    border: none;
    background: #e2e5ea;
    border-radius: 6px;
  }

  ::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
  }

  ::v-deep .el-tabs__item .iconfont {
    font-size: 18px;
    margin-right: 10px;
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.statisticsWrap {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.statisticsItem {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statisticsBox {
  width: 5vw;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 25px;
}

.statisticsBox_line {
  width: 2px;
  height: 70px;
  background: #ebebeb;
}

.statisticsBox_title {
  font-weight: 400;
  font-size: 14px;
}

.statisticsBox_num {
  font-weight: 400;
  font-size: 18px;
}
</style>
