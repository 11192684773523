<template>
  <div>
    <!-- 人员出入境 -->
    <head-layout
      head-title="人员出入境"
      :showIcon="false"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      class="itemListGrid"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-edit="rowEdit"
      @row-remove="rowRemove"
    >
    </grid-layout>
    <personEAExitAdd
      ref="personEAExitAdd"
      @callback="addCallback"
    ></personEAExitAdd>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import personEAExitAdd from "./personEAExitAdd.vue";
import { mapGetters } from "vuex";
import * as API from "@/api/siteManagement/personEAExit";
import { dateFormat } from "@/util/date";
export default {
  components: {
    GridLayout,
    HeadLayout,
    personEAExitAdd,
  },
  props: {
    prjName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableOption: {
        selection: false,
        menu: this.$route.query.type != "view",
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "性别",
            prop: "sex",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=project_sex_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            minWidth: 80,
            overHidden: true,
          },
          {
              label: "联系电话",
              prop: "phone",
              align: "center",
              minWidth: 180,
              overHidden: true,
            },
          {
            label: "状态",
            prop: "tripState",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            minWidth: 80,
            overHidden: true,
          },
          {
            label: "出行日期",
            prop: "startDate",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "出发地",
            prop: "startPlace",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "目的地",
            prop: "endPlace",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "登记人",
            prop: "createUserName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "人员姓名",
          prop: "userName",
          span: 4,
          placeholder: "请输入人员姓名",
        },
        {
          label: "",
          prop: "startDate",
          span: 4,
          type: "date-format",
          placeholder: "行程日期",
        },
        {
          label: "",
          prop: "tripState",
          span: 4,
          type: "select",
          placeholder: "状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      prjId: "",
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
    },
    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add",
            type: "button",
            icon: "",
            btnOptType: "add",
          },
        ];
      }
    },
  },

  methods: {
    init(prjId) {
      this.prjId = prjId;
      this.initDate();
    },
    initDate() {
      // this.$nextTick(()=>{
      //   this.$refs.gridHeadLayout.searchForm.startDate = dateFormat(new Date(),'yyyy-MM-dd');
      //   this.$refs.gridHeadLayout.searchForm.tripState = '1';
      //   this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
      // })
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.remove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      this.$refs.personEAExitAdd.init(
        "add",
        { prjId: this.prjId, prjName: this.prjName },
        {}
      );
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      // this.$refs.gridHeadLayout.searchForm = {};
      // this.onLoad(this.page, searchForm);
      this.initDate();
    },

    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params.prjId = this.prjId;
      API.getList(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$refs.personEAExitAdd.init(
        "edit",
        { prjId: this.prjId, prjName: this.prjName },
        row
      );
    },
    addCallback() {
      this.page.currentPage = 1;
      this.initDate();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .itemListGrid .avue-crud .el-table {
  height: calc(100vh - 350px) !important;
  max-height: calc(100vh - 350px) !important;
  box-sizing: border-box;
}
</style>
