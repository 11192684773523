<template>
  <div>
    <el-container>
      <div style="width: 100%; background: #ffffff" class="formMain">
        <head-layout
          head-title=""
          :showIcon="false"
          :head-btn-options="headBtnOptions"
          @head-save="headSave"
          @head-cancel="handleHeadGoback"
        />
        <div style="padding: 12px">
          <el-form
            ref="dataForm"
            :model="dataForm"
            label-width="110px"
            :disabled="formType == 'view'"
            :rules="rules"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="企业名称" prop="prjCode">
                  <avue-input-tree
                    :props="organDicProps"
                    checkStrictly
                    default-expand-all
                    v-model="dataForm.contractId"
                    placeholder="请填写备案单位 "
                    :dic="organDic"
                    disabled
                  ></avue-input-tree>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="项目名称" prop="prjName">
                  <el-input
                    v-model="dataForm.prjName"
                    disabled
                    placeholder="请输入项目名称"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="申报日期" prop="startDate">
                  <el-date-picker
                    v-model="dataForm.startDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择申报日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="有效日期" prop="endDate">
                  <el-date-picker
                    v-model="dataForm.endDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择有效日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="评选级别" prop="selectionLevel">
                  <el-select
                    v-model="dataForm.selectionLevel"
                    placeholder="请选择评选级别"
                  >
                    <el-option
                      v-for="item in selectionLevelList"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="评选状态" prop="selectionType">
                  <el-select
                    v-model="dataForm.selectionType"
                    placeholder="请选择评选状态"
                  >
                    <el-option
                      v-for="item in selectionTypeList"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="评选完成时间" prop="selectionYear">
                  <el-date-picker
                    v-model="dataForm.selectionYear"
                    type="year"
                    value-format="yyyy"
                    placeholder="选择评选完成时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="dataForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import { selectUserChildrenWithoudProject } from "@/api/system/dept";
import { dictionaryBiz } from "@/api/reportTasks";
import {
  greenFactorySubmit,
  greenFactoryDetail,
} from "@/api/safe/greenFactory";
export default {
  components: {
    HeadLayout,
  },
  props: {
    prjName: {
      type: "String",
    },
    contractId: {
      type: "String",
    },
    prjId: {
      type: "String",
    },
    detailId: {
      type: "String"
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择有效日期"));
      } else {
        const startDate = this.dataForm.startDate;
        if (startDate && value <= startDate) {
          callback(new Error("有效日期必须晚于申报日期"));
        } else {
          callback();
        }
        callback();
      }
    };
    return {
      prjId: "",
      formType: "",
      organDicProps: {
        label: "title",
        value: "id",
        children: "children",
      },
      organDic: [],
      dataForm: {
        startDate: "",
        endDate: "",
        selectionLevel: "",
        selectionType: "",
        selectionYear: "",
        remark: "",
        id: "",
      },
      rules: {
        startDate: [
          { required: true, message: "请选择申报日期", trigger: "change" },
        ],
        endDate: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        selectionLevel: [
          { required: true, message: "请选择评选等级", trigger: "change" },
        ],
        selectionType: [
          { required: true, message: "请选择评选状态", trigger: "change" },
        ],
        selectionYear: [
          {
            required: true,
            message: "请选择评选状态完成时间",
            trigger: "change",
          },
        ],
      },

      excavateTypeList: [],
      selectionLevelList: [],
      selectionTypeList: [],
    };
  },
  computed: {
    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: "saveBack",
          },
          {
            label: this.$t("cip.cmn.btn.celBtn"),
            emit: "head-cancel",
            type: "button",
            icon: "",
          },
        ];
      }
    },
  },

  mounted() {
    let type = this.$route.query.type;
    this.formType = type;
  },
  created() {
    this.dataForm.prjId = this.prjId;
    this.dataForm.prjName = this.prjName;
    this.dataForm.contractId = this.contractId;
    selectUserChildrenWithoudProject().then((res) => {
      let resData = res.data.data;
      this.organDic = resData;
    });

    this.getDictory();
    if(this.detailId){
      this.getDetail(this.detailId)
    }
  },
  methods: {
    getDetail(id) {
      greenFactoryDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          if (Object.keys(res.data.data).length) {
            Object.keys(this.dataForm).forEach((item) => {
              this.dataForm[item] = item=='contractId'?this.contractId:res.data.data[item];
            });
            this.dataForm.selectionLevel = String(this.dataForm.selectionLevel);
            this.dataForm.selectionType = String(this.dataForm.selectionType);
            this.dataForm.selectionYear = String(this.dataForm.selectionYear);
          }
        }
      });
    },
    getDictory() {
      dictionaryBiz("excavate_type").then((res) => {
        this.excavateTypeList = res.data.data;
      });
      dictionaryBiz("selection_level").then((res) => {
        this.selectionLevelList = res.data.data;
      });
      dictionaryBiz("selection_type").then((res) => {
        this.selectionTypeList = res.data.data;
      });
    },
    headSave() {
      this.$refs.dataForm.validate((vaild) => {
        if (vaild) {
          let params = JSON.parse(JSON.stringify(this.dataForm));
          params.prjId = this.prjId;
          delete params.prjName;
          delete params.contractId;
          greenFactorySubmit(params).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.$refs.dataForm.resetFields()
              this.handleHeadGoback()
            }
          });
        }
      });
    },
    handleHeadGoback() {
      this.$emit("callback");
    },
  },
};
</script>
<style scoped lang="scss"></style>
