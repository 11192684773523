<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="70%" class="qmDialog selectPeopleByCurrentPrjoect" @close="closeDialog">
    <el-container>
      <!-- 原核心人员 -->
      <!-- <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="项目角色授权" name="corePersonnel">
          <CommonTree
            ref="commonTreeCore"
            treeTitle="项目角色"
            :searchTitle="searchTitle"
            :treeData="corePersonnelTreeData"
            :defaultProps="defaultProps"
            @getNodeClick="corePersonnelTreeNodeClick"
            :isShowdig="false"
            :showCheckbox="false"
            :treeExpand="false"
            node-key="id"
          />
        </el-tab-pane>
        <el-tab-pane label="组织&人员" name="organizationPersonnel">
          <CommonTree
            ref="commonTree"
            treeTitle="项目架构"
            :searchTitle="searchOrTitle"
            :treeData="organizationPersonnelTreeData"
            :isShowdig="false"
            :defaultProps="organizationPersonnelDefaultProps"
            :showCheckbox="false"
            :treeExpand="true"
            @getNodeClick="organizationPersonnelTreeNodeClick"
            node-key="id"
          />
        </el-tab-pane>
      </el-tabs> -->
      <CommonTree ref="commonTree" treeTitle="项目架构" :searchTitle="searchOrTitle"
        :treeData="organizationPersonnelTreeData" :isShowdig="false" :defaultProps="organizationPersonnelDefaultProps"
        :showCheckbox="false" :treeExpand="true" @getNodeClick="organizationPersonnelTreeNodeClick" node-key="id" />
      <el-main>
        <!-- <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout> -->

        <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="organizationPersonnelTableData"
          :table-loading="tableLoading" :data-total="page.total" :page="page" @page-current-change="onLoad"
          @page-size-change="onLoad">
          <template slot="birthday" slot-scope="{ row }">
            <span>
              {{
    row.birthday && row.birthday != "" ? getAge(row.birthday) : ""
  }}
            </span>
          </template>
          <template #customBtn="{ row }">
            <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </el-dialog>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import { prjbasicinfoPage } from "@/api/safe/itemList";
import {
  prjroleuserList,
  classificationRoleList,
  prjstructureList,
  prjstructureuserList,
} from "@/api/safe/itemList";
import { classificationRoleGetList } from "@/api/system/role";
import { calculateAge } from "@/util/util";
import { mapGetters } from "vuex";
export default {
  components: {
    CommonTree,
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "项目名称",
          prop: "prjCode",
          span: 5,
          placeholder: "项目名称或编号",
        },
        {
          label: "立项日期",
          prop: "prjDate",
          span: 5,
          type: "date-format",
          placeholder: "请选择立项日期",
        },
      ],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOption: {
        selection: false,
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            overHidden: true,
          },
          {
            label: "性别",
            prop: "sex",
            width: 100,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            width: 100,
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "组织名称",
            prop: "structureName",
            minWidth: 120,
            align: "center",
            overHidden: true,
            slot: false,
          },
          {
            label: "启用",
            prop: "isUsed",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            align: "center",
          },
          // {
          //   label: "更新时间",
          //   prop: "updateTime",
          //   width: 200,
          //   align: "center",
          //   overHidden: true,
          // },
        ],
      },
      tableData: [],
      prjId: "",
      prjName: "",
      activeName: "corePersonnel",
      defaultProps: {
        children: "children",
        label: "roleName",
      },
      searchTitle: "roleName",
      corePersonnelTreeData: [],
      corePersonnelTreeNode: "",
      corePersonnelTableData: [],
      organizationPersonnelTreeData: [],
      searchOrTitle: "structureName",
      organizationPersonnelDefaultProps: {
        children: "children",
        label: "structureName",
      },
      organizationPersonnelTreeNode: "",
      organizationPersonnelTableData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    handleTabClick(tab) {
      if (tab.name == "corePersonnel") {
        this.organizationPersonnelTableData = [];
        this.getClassificationRoleList();
        this.getPrjroleuserList();
      } else if (tab.name == "organizationPersonnel") {
        this.corePersonnelTableData = [];
        this.getPrjstructureList();
        this.getPrjstructureuserList();
      }
    },
    //获取项目角色
    getClassificationRoleList() {
      // classificationRoleList({
      //   current: 1,
      //   size: 9999,
      // }).then((res) => {
      //   this.corePersonnelTreeData = res.data.data.records;
      //   if (this.corePersonnelTreeData.length) {
      //     this.$nextTick(() => {
      //       this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
      //         this.corePersonnelTreeData[0].id
      //       );
      //       this.corePersonnelTreeNodeClick(this.corePersonnelTreeData[0]);
      //     });
      //   }
      // });
      classificationRoleGetList(1, 9999, { typeCode: "PROJECT" }).then(
        (res) => {
          this.corePersonnelTreeData = res.data.data.records;
          if (this.corePersonnelTreeData.length) {
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.corePersonnelTreeData[0].id
              );
              this.corePersonnelTreeNodeClick(this.corePersonnelTreeData[0]);
            });
          }
        }
      );
    },
    //根据角色获取人员列表
    getPrjroleuserList() {
      prjroleuserList({
        prjId: this.prjId,
        roleId: this.corePersonnelTreeNode.id,
      }).then((res) => {
        this.corePersonnelTableData = res.data.data;
        this.tableData = res.data.data;
      });
    },
    // 项目角色人员查询
    corePersonnelTreeNodeClick(node) {
      this.corePersonnelTreeNode = node;
      this.getPrjroleuserList();
    },
    //项目组织架构
    getPrjstructureList() {
      prjstructureList({
        prjId: this.prjId,
      }).then((res) => {
        this.organizationPersonnelTreeData = [
          {
            id: "",
            structureName: this.prjName,
            children: [],
          },
        ];
        if ((res.data.code = 200 && res.data.data.length)) {
          this.organizationPersonnelTreeData = [
            {
              id: "",
              structureName: this.prjName,
              children: res.data.data,
            },
          ];

          this.$nextTick(() => {
            this.$refs.commonTree.$refs.commonTree.setCurrentKey(
              this.organizationPersonnelTreeData[0].id
            );
            this.organizationPersonnelTreeNodeClick(
              this.organizationPersonnelTreeData[0]
            );
          });
        }
      });
    },
    //项目组织架构人员
    getPrjstructureuserList() {
      prjstructureuserList({
        prjId: this.prjId,
        structureId: this.organizationPersonnelTreeNode.id,
      }).then((res) => {
        this.organizationPersonnelTableData = res.data.data;
        this.tableData = res.data.data;
      });
    },
    //项目组织架构树点击
    organizationPersonnelTreeNodeClick(node) {
      this.organizationPersonnelTreeNode = node;
      this.getPrjstructureuserList();
    },

    init(prjId, prjName) {
      this.showDialog = true;
      this.title = "选择人员";
      this.prjId = prjId;
      this.prjName = prjName;
      // this.getClassificationRoleList();
      // this.getPrjroleuserList();
      this.getPrjstructureList();
      this.getPrjstructureuserList();
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      prjbasicinfoPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    //获取年龄
    getAge(data) {
      return calculateAge(data);
    },
    // 选择项目
    rowSelect(row) {
      this.showDialog = false;
      this.$emit("callback", row);
    },
    closeDialog() {
      // this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.selectPeopleByCurrentPrjoect .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
