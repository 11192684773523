<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-10-09 09:25:23
-->
<template>
  <el-dialog title="选择闸机" top="5vh" :modal-append-to-body="false" :append-to-body="true" :close-on-click-modal="false" :visible.sync="showDialog" v-if="showDialog" width="1240px" class="qmDialog" @close="closeDialog">
    <el-container>
      <el-main>
        <div class="headers">
          <grid-head-layout ref="gridHeadLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :search-columns="searchColumns" @grid-head-search="searchChange" @grid-head-empty="searchReset">
          </grid-head-layout>
        </div>
        <grid-layout ref="gridLayout" class="userSelectGridLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :data-total="page.total" @page-size-change="onLoad" @page-current-change="onLoad" @page-refresh-change="onLoad" :page="page" @gird-handle-select-click="selectionChange" :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
          :gridRowBtn="gridRowBtn">
          <template slot="birthday" slot-scope="{ row }">
            <span :style="itemObject(row)">
              {{
                row.birthday && row.birthday != "" ? getAge(row.birthday) : ""
              }}
            </span>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleSave" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    getList
  } from "@/api/safe/gate";
  import {
    calculateAge
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import CommonTree from "@/views/components/com_tree";
  import TreeLayout from "@/views/components/layout/tree-layout";
  export default {
    components: {
      GridLayout,
      CommonTree,
      TreeLayout,
    },
    data() {
      return {
        treeLoading: true,
        showDialog: false,
        treeDialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: "",
        treeData: [],
        gridRowBtn: [],
        searchTitle: "title",
        defaultProps: {
          label: "title",
          value: "key",
        },
        treeDeptId: "",
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        tableOptions: {
          menu: false,
          reserveSelection: true,
          column: [{
              label: "设备号",
              prop: "eqtCode",
              align: "center",
              overHidden: true,
            },
            {
              label: "设备名称",
              prop: "eqtName",
              align: "center",
              overHidden: true,
            },
            {
              label: "设备类型",
              prop: "eqtType",
              align: "center",
              // dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
            },
            {
              label: "型号规格",
              prop: "modelSpec",
              align: "center",
              overHidden: true,
            },
            {
              label: "厂商名称",
              prop: "manufacturerName",
              align: "center",
              overHidden: true,
            },
            {
              label: "安装位置",
              prop: "installSite",
              width: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: "设备状态",
              prop: "eqtStatus",
              width: 100,
              align: "center",
              dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineStatus",
            },
          ],

        },
        selectionList: [],
        oldSelectionList: [],
        parentPrjId: '',
        parentGateMachineId: '',
        first:true,
      };
    },
    computed: {

      searchColumns() {
        return [{
            label: "设备号",
            prop: "eqtCode",
            span: 4,
            placeholder: "请输入设备号",
          },
          {
            label: "设备名称",
            prop: "eqtName",
            span: 4,
            placeholder: "请输入设备名称",
          },
          {
            label: "厂商名称",
            prop: "manufacturerName",
            span: 4,
            placeholder: "请输厂商名称",
          },
        ];
      },

    },
    props: {
      userSure: { // 为true时，点击确认不关闭弹窗，需在父组件手动关闭
        type: Boolean,
        default: false
      }
    },
    methods: {
      //获取年龄
      getAge(data) {
        return calculateAge(data);
      },
      init(data, prjId, rowid) {
        this.showDialog = true
        this.oldSelectionList = rowid;
        this.parentPrjId = prjId
        this.parentGateMachineId = rowid
        this.selectionList = []
        if (rowid.length && rowid.length == 1) {
          this.selectionList = rowid[0].gateMachineVOList
        }

        this.$nextTick(() => {
          //暂时注释用data里的数据
          // this.getTreeData();
          setTimeout(() => {
            this.$refs.gridLayout.$refs.grid.toggleSelection()
          }, 100);
          this.onLoad(this.page);
        });
      },
      includeDown(e) {
        this.page.currentPage = 1;
        this.query.isInclude = e;
        this.onLoad(this.page);
      },
      onLoad(page, params = {}) {
        this.page = page;
        this.tableLoading = true;
        params.prjId = this.parentPrjId
        getList(page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          const data = res.data.data;

          this.tableData = data.records;
          this.tableLoading = false;
          this.$nextTick(() => {
            this.$refs.gridLayout.page.total = data.total;
            // let checkData = []
            // if (this.oldSelectionList.length == 1) {
            //   if (this.tableData.length) {
            //     this.tableData.map((item, index) => {
            //       let findItem = this.selectionList.find((val) => {
            //         if (val.id === item.id) {
            //           return val
            //         } else {
            //           return null
            //         }
            //       })
            //       if (findItem) {
            //         setTimeout(() => {
            //           this.$refs.gridLayout.$refs.grid.toggleRowSelection(this.tableData[index], true);
            //         }, 100);
            //       }
            //     })

            //   }
            // }


          });
        });
      },
      selectionChange(list) {
        // if(this.oldSelectionList.length && this.oldSelectionList.length==1&&this.first){
        //   console.log('===',list)
        //   list=this.oldSelectionList[0].gateMachineVOList
        //   this.first=false
        // }
        // console.log('==ee=',list)
        this.selectionList = list;
      },
      getNodeClick(data) {
        this.treeDeptId = data.id;
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      searchChange() {
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      searchReset() {
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      /**
       * 部门树结构点击事件
       * @param node
       */
      treeNodeClick(node) {
        this.treeDeptId = node.id;
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      // 新增左侧树节点
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      handleSave() {
        if (this.selectionList.length) {
          this.showDialog = false;
          this.$emit("callBack", this.selectionList);
          this.$refs.gridHeadLayout.searchForm = {};
          this.page.currentPage = 1;
        } else {
          this.$message.warning("请选择至少一条数据");
        }

      },
      closeDialog() {
        this.$refs.gridHeadLayout.searchForm = {};
        this.page.currentPage = 1;
        this.treeDeptId = "";
        // this.$refs.CommonTree.filterText = ''
        this.showDialog = false;
      },
    },
  };

</script>
<style scoped lang="scss">
  ::v-deep .userSelectGridLayout .avue-crud .el-table {
    height: 50vh !important;
    max-height: 50vh !important;
    box-sizing: border-box;
  }

  ::v-deep .userSelectTreeLayout .el-tree {
    height: 50vh !important;
    max-height: 50vh !important;
    overflow-y: scroll;
  }

</style>
